<template>
	<div class="news_mian">
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">当前位置：首页</el-breadcrumb-item>
			<el-breadcrumb-item>{{ gettitle() }}</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="cont_mian">
			<div class="left">
				<div class="home-select">
					<el-form>
						<el-form-item>
							<el-col :span="20">
								<el-input v-model="selfrom.searchKey" placeholder="请输入关键词"></el-input>
							</el-col>
							<el-col :span="4">
								<el-button @click="search" type="primary">搜索</el-button>
							</el-col>
						</el-form-item>
					</el-form>
				</div>
				<div class="hotnews">
					<h3>热门新闻</h3>
					<ul>
						<li v-for="item in hotnewslist" :key="item.id" @click="goto('/info', { id: item.id })">
							{{ item.cTITLE }}
						</li>
					</ul>
				</div>
				<div class="newstype">
					<h3>资讯栏目</h3>
					<div v-for="item in llsit" :key="item.codE_VALUE" class="type_mian"
						:class="selfrom.types === item.codE_VALUE ? 'checket' : ''" @click="changType(item.codE_VALUE)">
						<span class="typename">{{ item.codE_NAME }}</span>
					</div>
				</div>
			</div>
			<div class="right">
				<div class="title">{{ gettitle() }}</div>

				<div class="new_mian">
					<div class="new_item" v-for="item in newslist" :key="item.id" @click="goto(item)">
						<div class="titles">
							<div class="yuan"></div>
							<div :class="[
                  item.cRED == 1 ? 'red' : '',
                  ,
                  item.cWEIGHT == 1 ? 'weight' : '',
                ]">
								{{ item.cTITLE }}
							</div>
						</div>
						<div>{{ stringToDates(item.dREDATE) }}</div>
					</div>
					<el-empty v-if="newslist.length < 1" :image-size="200"></el-empty>
					<div style="text-align: center; margin-top: 20px">
						<el-pagination hide-on-single-page @size-change="handleSizeChange"
							@current-change="handleCurrentChange" :current-page="selfrom.pageNumber"
							:page-sizes="[10, 20, 30, 40]" :page-size="selfrom.pageSize"
							layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import utils from "@/assets/js/utils";
	import {
		isnull
	} from "@/assets/js/index";
	import global from "@/assets/js/globalconfig";
	import {
		Getnewtypelist,
		Getnewslist,
		GetHotnewslist
	} from "@/api/home";
	export default {
		data() {
			return {
				defimgurl: global.baseimgurl,
				selfrom: {
					types: "1008",
					pageNumber: 1,
					pageSize: 20,
					searchKey: "",
				},
				selfromhot: {
					types: "1008",
					pageNumber: 1,
					pageSize: 5,
					searchKey: "",
				},
				total: 0,
				llsit: [],
				newslist: [],
				hotnewslist: [],
			};
		},
		created() {
			var id = this.$route.query.id;
			var cTID = localStorage.getItem("cTID");

			if (id) {
				this.selfrom.types = this.selfromhot.types = id;
			} else if (cTID) {
				this.selfrom.types = this.selfromhot.types = cTID;
				localStorage.removeItem("cTID");
			}
			var page = "";
			try{
				page = localStorage.getItem("newspage") ?
					JSON.parse(localStorage.getItem("newspage")) :
					"";
			}catch(e){}
			
			if (page.types == this.selfrom.types) {
				this.selfrom.pageNumber = page.pageNumber;
				this.selfrom.pageSize = page.pageSize;
			}
			this.GetTypeList();
		},
		methods: {
			stringToDates(data) {
				return utils.stringToDate(data);
			},
			goto(data) {
				if (data.cURL) {
					this.resolvewin(data.cURL, {}, 3);
				} else {
					localStorage.setItem("newspage", JSON.stringify(this.selfrom));
					this.resolvewin('/info', {
						id: data.id
					}, 1);
				}
			},
			GetTypeList() {
				var _this = this;
				Getnewtypelist().then((res) => {
					console.log(res)
					if (res.success) {
						_this.llsit = res.data;
						if (!_this.selfrom.types) {
							_this.selfrom.types = res.data[0].codE_VALUE;
							_this.selfromhot.types = res.data[0].codE_VALUE;
						}

						_this.GetNewList();
						_this.GetHotNewList();
					}
				});
			},
			GetHotNewList() {
				var _this = this;
				this.selfromhot.showLoadType = ".new_mian";
				GetHotnewslist(this.selfromhot).then((res) => {
					if (res.success) {
						_this.hotnewslist = res.data.rows;
					}
				});
			},
			GetNewList() {
				var _this = this;
				this.selfrom.showLoadType = ".new_mian";
				Getnewslist(this.selfrom).then((res) => {
					if (res.success) {
						_this.newslist = res.data.rows;
						_this.total = res.data.total;
					}
				});
			},
			changType(id) {
				this.selfrom = {
					types: id,
					pageNumber: 1,
					pageSize: 20,
				};
				this.GetNewList();
			},
			gettitle() {
				var index = this.llsit.findIndex((res) => {
					return res.codE_VALUE === this.selfrom.types;
				});
				if (index >= 0) {
					return this.llsit[index].codE_NAME;
				} else {
					return "";
				}
			},
			handleSizeChange(val) {
				this.selfrom.pageNumber = 1;
				this.selfrom.pageSize = val;
				this.GetNewList();
			},
			handleCurrentChange(val) {
				this.selfrom.pageNumber = val;
				this.GetNewList();
			},
			search() {
				this.selfrom.pageNumber = 1;
				this.GetNewList();
			},
		},
	};
</script>
<style lang="scss" scoped>
	.news_mian {
		width: 1200px;
		margin: 0 auto;
		padding: 20px 0;
	}

	.home-select {
		margin: 0px auto;
		padding: 0px;

		.el-form {
			width: 100%;
			background: #fff;

			.el-form-item {
				.el-form-item__content {
					height: 30px;
					line-height: 30px;

					.el-input ::v-deep {
						.el-input__inner {
							height: 30px;
							line-height: 30px;
							border: none;
							border-radius: 0;
							font-size: 14px;
						}

						.el-input__inner::placeholder {
							color: #333;
						}
					}
				}
			}


			.el-button {
				width: 100%;
				line-height: 32px;
				text-align: center;
				border-radius: 0;
				padding: 0;
				font-size: 14px;
				background-color: #467DF2;
			}
		}

		.hot-word {
			text-align: left;
			font-size: 16px;
			color: #787878;

			a {
				padding: 0px 12px;
				color: #409eff;
				text-decoration: none;
			}
		}

	}

	.hotnews {
		background-color: #fff;
		padding: 15px 12px;

		h3 {
			margin: 0 0 10px 0;
			font-weight: initial;
			font-size: 18px;
			color: #101010
		}

		ul {
			margin: 0;
			padding: 0;

			li {
				list-style: none;
				line-height: 20px;
				height: 20px;
				overflow: hidden;
				text-overflow: ellipsis;
				-o-text-overflow: ellipsis;
				-webkit-text-overflow: ellipsis;
				-moz-text-overflow: ellipsis;
				white-space: nowrap;
				margin: 10px 0 0;
				font-size: 14px;
				color: #898989;
				cursor: pointer;
			}

			li:hover {
				color: #409eff;
			}
		}
	}

	.cont_mian {
		display: flex;
		justify-content: space-between;

		.left,
		.right {
			min-height: calc(100vh - 310px);
			margin-top: 15px;
		}

		.left {
			width: 280px;
			font-size: 14px;

			.checket {
				// border-left: 5px solid #409eff;
				color: #409eff;

				.typename {
					margin-left: 0px;
				}
			}

			.newstype {}

			.type_mian {
				cursor: pointer;
				padding: 10px 25px;
				background-color: #fff;
				// margin: 10px 0;
			}

			.type_mian:hover {
				color: #409eff;
			}

			.typename {
				margin-left: 0px;
			}
		}

		.right {
			background-color: #fff;
			padding: 10px;
			width: 880px;

			.title {
				font-size: 16px;
				font-weight: bold;
				padding: 20px;
				border-bottom: 1px solid #dcdfe6;
				margin-bottom: 10px;
			}

			.new_item {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 10px 5px;
				font-size: 14px;
				cursor: pointer;

				.titles {
					display: flex;
					align-items: center;
				}

				.yuan {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background-color: #ea5404;
					margin: 0 15px;
				}
			}

			.new_item:hover {
				color: #409eff;
			}
		}
	}

	.red {
		color: #ff0000;
	}

	.weight {
		font-weight: bold;
	}
</style>